<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id"><FormClient :client_id="client_id" @getClient="getClient" /><br /></div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->
                <div class="dark-text header-w-bg">Adult 65D-30 Outpatient ASAM Level I</div>
                <h3>[2] Continued Stay Criteria</h3>

                <p>
                    Check all items in each dimension that apply to the client. Check the <b>appropriate</b> box that
                    indicates validation or lack of validation for continued stay in the level of care.
                </p>
                <Radios
                    class="block radios asam-radios"
                    v-model="rec.meets_criteria"
                    label=""
                    :options="['Meets criteria in Dimensions 1 and 2 and one of Dimensions 3, 4, 5 or 6.']"
                />

                <div class="avoid-break">
                    <h3>Dimension 1: Acute Intoxication and/or withdrawal potential:</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_one"
                        label=""
                        :options="['Client is free from intoxication or withdrawal symptoms/risks.']"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 2: Biomedical conditions and complications:</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_two"
                        label="None or very stable - The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. The client’s biomedical conditions are stable enough to continue to participate in outpatient treatment; or',
                            'b. An intervening problem interrupted treatment but is now stable enough for the client to progress in treatment.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 3: Emotional, behavioral or cognitive conditions and complications:</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_three"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a.  The client is making progress to reduce anxiety, guilt or depression, if present, but these symptoms have not been sufficiently resolved to permit discharge from treatment; or',
                            'b. An intervening emotional, behavioral or cognitive condition interrupted treatment but is now stable enough for the client to progress in treatment.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 4: Readiness to change:</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_four"
                        label=""
                        :options="[
                            'Willing to cooperate but needs motivating and monitoring strategies - The client continues to work on treatment goals and objectives, but does not yet understand or accept his/her addiction sufficiently to maintain a self-directed recovery plan.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 5: Relapse/Continued Use Potential:</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_five"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. Client is not using alcohol or other drugs but remains mentally preoccupied with such use to the extent he/she is unable to address primary relationships, social or work tasks; however, there are indications that with continued treatment, the client will effectively address these issues; or',
                            'b. Client is not using alcohol or drugs and demonstrates minimal cravings but requires continued work on the development of an alternative lifestyle, thinking patterns and emotional responses; however, the client is making progress toward these goals.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 6: Recovery environment</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_six"
                        label=""
                        :options="[
                            'Supportive recovery environment and/or client has skills to cope – The social environment remains non-supportive or has deteriorated, but the client is making sufficient progress in learning social and related coping skills to function in the environment without using substances.'
                        ]"
                    />
                </div>
                <ExpandableTextArea
                    class="avoid-break block top-30"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="recommendations"
                    v-model="rec.recommendations"
                    label="Recommendations/Notes:"
                    :limit=4000
                    inputStyle="min-height:20px;"
                />

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right inline-block secondary right-15"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />
                </div>
                <FormSignature
                    :user_id="user_id"
                    :signature_ids="signature_ids"
                    ref="form_signatures"
                    @getSignatures="getSignatures"
                    v-if="signature_ids.length > 0"
                />
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    export default {
        name: 'OpContinuedStayASAM',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                this.loading = 0;
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
